"use client"

import { IntercomProps, IntercomProvider } from "react-use-intercom"
import React from "react"
import useAuth from "@/hooks/useAuth"

const IntercomProviderClient = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { user, masquerade } = useAuth()
  const autoBootProps: IntercomProps = user?.id
    ? {
        name: `${user.first_name} ${user.last_name}`,
        customAttributes: {
          user_id: user.id,
          email: user.email,
          profile: masquerade?.name,
        },
      }
    : undefined
  return (
    <IntercomProvider
      shouldInitialize={
        !!process.env.NEXT_PUBLIC_INTERCOM_API_KEY && !!user?.id
      }
      autoBoot={!!user?.id}
      autoBootProps={autoBootProps}
      appId={process.env.NEXT_PUBLIC_INTERCOM_API_KEY}
    >
      {children}
    </IntercomProvider>
  )
}

export default IntercomProviderClient
