import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface ProfileState {
  connectionData: Record<string, Record<string, number>> | null
}

const initialState: ProfileState = {
  connectionData: null,
}

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setConnectionData(
      state,
      action: PayloadAction<ProfileState["connectionData"] | null>
    ) {
      // eslint-disable-next-line no-param-reassign
      state.connectionData = action.payload
    },
  },
})

export const profileActions = profileSlice.actions

export default profileSlice
