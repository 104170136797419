import type { Image as ImageType, Image } from "./Image"
import { Opportunity } from "./Opportunity"
import { TeamMember } from "./TeamMember"
import type { User } from "./User"

export enum PROJECT_STATUS {
  DRAFT = "Draft",
  OPEN = "Open",
  IN_CONVERSATION = "In Conversation",
  UNDER_CONTRACT = "Under Contract",
  AWAITING_COSIGN = "Awaiting co-sign",
  ACTIVE = "Active",
  COMPLETED = "Completed",
  ARCHIVED = "Archived",
  IN_RESOLUTION = "In Resolution",
  CANCELLED = "Canceled",
}

export type ProjectCreator = Pick<
  User,
  "first_name" | "id" | "last_name" | "profile_image"
>

export type Project = {
  id?: string
  profile_name?: string
  profile_hero?: Image
  profile_slug: string
  profile_type?: "Artist" | "Brand"
  name: string
  type: string
  location?: string
  project_images?: Image[]
  project_overview?: string
  hero?: Image // TODO: Remove this
  slug: string
  status: PROJECT_STATUS
  opportunities: any
  cost_start?: number
  is_public?: boolean
  created_by?: Pick<User, "first_name" | "last_name" | "id" | "profile_image">
  created_on?: string
  project_start_date?: string
  project_end_date?: string
  related_projects?: Omit<Project, "related_projects">[]
  partnerships: { name?: string; id?: number }[]
  userName: string
  userImage: string
  project_image?: ImageType
}

export type UnlockedProject = Project & {
  project_files: { image_src: string; file_size: number; file_type: string }[]
  opportunities: Opportunity[]
  demo_indexes?: { id: number; name: string }[]
  profile_slug: string
  id: string
  calendar_events?: {
    id: number
    calendar_event: {
      id: number
      link: string
      market: string
      name: string
      notes: string
      quater: string
      categories: { id: number; name: string }[]
    }
  }[]
  tags?: { id: number; name: string }[]
  team: TeamMember[]
}

export type InboundProject = Partial<UnlockedProject> & {
  status: string
  requests?: number
  created_by?: ProjectCreator
  is_public?: boolean
  created_on?: string
  modified_on?: string
  project_image?: ImageType
  opportunities: number
}
