import {
  FC,
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import clsx from "clsx"

import ErrorWithData from "lib/ErrorWithData"

import ErrorUnwrapper from "components/ErrorUnwrapper"

import CheckmarkIcon from "icons/Checkmark"
import CloseIcon from "icons/Close"
import ErrorIcon from "icons/Error"
import { XSmall } from "styles/Type"

import InfoIcon from "@/icons/InfoIcon"
import TOAST_STYLES from "./styles"
import { ToastProps } from "./types"

const ICON_MAP = {
  success: CheckmarkIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const TOAST_TIMEOUT = 3000
const SOFT_CLOSE_TIMEOUT = 500

export const Toast: FC<
  ToastProps & HTMLAttributes<HTMLDivElement> & { close: () => void }
> = ({ type, className = "", close, ...props }) => {
  const Icon = ICON_MAP[type]
  const [isClosing, setIsClosing] = useState(false)
  const timeout = useRef<ReturnType<typeof setTimeout>>(null)

  const softClose = useCallback(
    (e) => {
      e?.stopPropagation()
      setIsClosing(true)
      setTimeout(close, SOFT_CLOSE_TIMEOUT)
    },
    [close]
  )

  const abortAutoClose = useCallback(() => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
  }, [])

  useEffect(() => {
    timeout.current = setTimeout(softClose, TOAST_TIMEOUT)
    return () => {
      clearTimeout(timeout.current)
    }
  }, [close, softClose, type])

  return (
    <div
      className={clsx(
        className,
        TOAST_STYLES[type],
        "p-4 rounded-md",
        "flex items-start",
        "animate-slide-in",
        isClosing && "opacity-0 translate-x-full",
        "transition duration-500",
        "px-[20px] py-[16px]"
      )}
      {...props}
      role="alert"
      onMouseOver={abortAutoClose}
      onFocus={abortAutoClose}
    >
      <Icon className="w-5 h-5 mr-3 flex-shrink-0" strokeWidth="1.5" />
      <div className="flex-grow w-0">
        {props?.html}
        <XSmall className="font-medium">
          {(typeof props.body === "string" && props.body) ||
            (typeof props.body === "object" && props.body.message)}
        </XSmall>
        {typeof props?.body === "object" &&
          "detail" in props.body &&
          props.body.detail && <XSmall>{props.body.detail}</XSmall>}
        {props.body instanceof ErrorWithData &&
          props.body?.data &&
          typeof props.body?.data === "object" && (
            <XSmall as="div">
              <ErrorUnwrapper error={props.body.data} />
            </XSmall>
          )}
      </div>
      <button
        type="button"
        className="ml-3 flex-shrink-0 flex"
        onClick={softClose}
      >
        <span className="sr-only">Dismiss notification</span>
        <CloseIcon className="w-5 h-5" />
      </button>
    </div>
  )
}

export default Toast
